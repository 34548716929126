<template>
    <div>
        <div class="load" v-if="!res.coupon_name"><a-spin /></div>
        <div class="coupon-detail" v-if="res.coupon_name">
            <!-- <div v-if="res.coupon_name && !res.background_img">
                <img class="bg_img" :src="require('@/static/images/coupon_bg.jpg')" alt="">
            </div> -->
            <div v-if="res.coupon_name && res.background_img">
                <img class="bg_img" :src="res.background_img" alt="">
            </div>
            <div class="intro">
                <div class="intro-title">{{ res.coupon_name }} 【<span>{{ res.type_text }}</span>】</div>
                <div class="intro-price" v-if="res.need_buy != 0">
                    <div>
                        <s>原价{{ res.price }}</s> {{ res.buy_price }}<span>元</span>
                    </div>
                </div>
                <div style="margin-bottom: 10px; margin-top: 20px">
                    <p style="font-weight: 600; margin-bottom: 2px">活动有效期</p>
                    {{ res.start_time }} 至 {{ res.end_time }}
                </div>
                <div>
                    <p style="font-weight: 600; margin-bottom: 2px">使用时间</p>
                    {{ res.use_start_time }} 至 {{ res.use_end_time }}
                </div>
            </div>
            <div class="rule">
                <div class="rule-title">使用规则</div>
                <div class="rule-content" v-html="res.desc"></div>
            </div>
            <!-- <div class="rule-btn" @click="checkRule" v-if="res.desc">使用规则</div> -->
            <div class="coupon-detail-box">
               
                <div class="btn" @click="couponReceive">
                    <span v-if="res.need_buy">{{ res.buy_price }}元</span>
                    {{ res.status == 1 ? res.need_buy ? '立即购买' : '立即领取' :'已失效' }}
                </div>
                <div class="my"><a @click="$router.push('/coupon')">我的卡券</a></div>
                <!-- <div class="my" @click="payHanld">支付测试</div> -->
            </div>
            <!-- <div class="content">
            <div>
                
            </div>
            </div> -->
        </div>
        <!-- 留资弹窗 -->
        <van-dialog 
            v-model="showFrom" 
            :confirm-button-color="confirmButtonColor"
            show-cancel-button 
            :confirm-button-text="res.need_buy?'确认购买':'确认领取'" @confirm="confirmForm"
            :before-close="onBeforeClose"
        >
            <div style="padding: 30px 10px">
                <van-cell-group :border="false">
                    <van-field 
                        v-model="form.name" 
                        label="姓名" 
                        left-icon="user-o" 
                        label-width="4rem" 
                        placeholder="请输入姓名" 
                        border/>
                </van-cell-group>
                <van-cell-group :border="false">
                    <van-field 
                        type="number" 
                        maxlength="11" 
                        v-model="form.phone" 
                        label="电话" 
                        left-icon="phone-o" 
                        label-width="4rem" 
                        placeholder="请输入手机号" 
                        border/>
                </van-cell-group>
                <!-- <a-form-model ref="ruleForm" :model="ruleForm" :rules="rules" v-bind="layout">
                    <a-form-model-item has-feedback label="Password" prop="pass">
                    <a-input v-model="ruleForm.pass" type="password" autocomplete="off" />
                    </a-form-model-item>
                    <a-form-model-item has-feedback label="Confirm" prop="checkPass">
                    <a-input v-model="ruleForm.checkPass" type="password" autocomplete="off" />
                    </a-form-model-item>
                </a-form-model> -->
            </div>
        </van-dialog>
    </div>
</template>
<script>
// window.addEventListener("pagehide", function() {
//     // 测试接口
//     let parames = {
//     data: {
//         id: 10000
//     }
//     };
//     navigator.sendBeacon( process.env.VUE_APP_API_BASE_URL + '/operation/articleSpread/show', parames);
// })


import Vue from "vue"
import { mapActions, mapGetters } from 'vuex'
import { couponShowApi, couponReceiveApi, buyCoupon } from "@/api/coupon"
import { getSdkConfig } from '@/api/common'
import storage from 'store'
import { Toast, Dialog, Field, Cell, CellGroup } from 'vant'
Vue.use(Toast)
Vue.use(Dialog)
Vue.use(Field)
Vue.use(Cell)
Vue.use(CellGroup)
export default {
    components: {
        Toast,
        Dialog,
        Field,
        Cell,
        CellGroup
    },
    data () {
        return {
            confirmButtonColor: '#1989fa',
            url: window.location.href,
            res: {},
            showFrom: false,
            value: '',
            form: {
                name: '',
                phone: ''
            },
            rules: {},
            layout: {
                labelCol: { span: 4 },
                wrapperCol: { span: 14 },
            },
        }
    },
    created () {
        this.cid = this.$route.query.cid || storage.get('cid')
        this.contact_id = this.$route.query.contact_id || ''
        this.check_code = this.$route.query.check_code || storage.get('check_code')
        this.couponId = this.$route.query.id
        this.getOpenUserInfo()
    },
    mounted () {
        // console.log(this.$wx.pay)
    },
    methods: {
        ...mapActions(['Login']),
        confirmForm () {
            if(!this.form.name){
                Toast('请填写姓名')
                return
            }
            if(!this.form.phone || !(/^1[3456789]\d{9}$/.test(this.form.phone))){
                Toast('请正确填写手机号码')
                return
            }
            if(this.res.need_buy == 1){
                this.payHanld()
            }else{
                let params = {
                    id: this.res.id,
                    contact: this.form.name,
                    phone: this.form.phone
                }
                if (this.res.limit_contact == 1) {
                    params.contact_id = this.contact_id
                }
                this.sendCouponReceiveApi(params)
                Dialog.close()
            }
        },
        onBeforeClose (action, done) {
            if(action === 'confirm'){
                return done(false)
            }else{
                done()
            }
        },
        payHanld () {
            var origin = window.location.origin;
            var a = origin.substr(5).search(":");
            var uri = origin + window.location.pathname;
            // 初始化jssdk, 获得接口权限
            getSdkConfig({
                url: uri,
                apiList: ['chooseWXPay']
            }).then(res => {
                // 调起支付
                this.$wx.init(res.data, () => {
                    buyCoupon({ 
                        id: this.res.id
                    }).then(order => {
                        if(order.data && order.data.order_sn){
                            this.$wx.pay(order.data, () => {
                                Toast.loading({
                                    message: '支付中'
                                })
                                var i = 0
                                // 查询订单是否支付成功
                                const checkOrder = (params) => {
                                    couponReceiveApi(params).then(res => {
                                        if(res.code == 200){
                                            Toast.success('奖励领取成功')
                                            setTimeout(() => {
                                                this.$router.push({path: '/coupon', query: {cid: this.cid}})
                                            }, 2000);
                                            Dialog.close()
                                        }else{
                                            console.log(i)
                                            if(i < 2){
                                                i++
                                                checkOrder(params)
                                            } else {
                                                Toast(res.msg)
                                            }
                                        }
                                    })
                                }

                                let checkData = {
                                    id: this.res.id,
                                    order_sn: order.data.order_sn
                                }

                                if(this.res.need_info){
                                    checkData.phone = this.form.phone
                                    checkData.contact = this.form.name
                                }

                                if (this.res.limit_contact == 1) {
                                    checkData.contact_id = this.contact_id
                                }

                                checkOrder(checkData)
                            })
                        }else{
                            Toast.fail(order.msg)
                        }
                    })
                })
            })
        },
        getOpenUserInfo () {
            let that = this;
            that.Login({
                cid: that.cid,
                check_code: that.check_code
            }).then((res) => {
                console.log(res)
                if (!res.data.token) {
                    let redirectUrl = '/auth/officialAccount?cid='+that.cid+'&check_code=' + that.check_code + '&module=officialAccount&target=' + encodeURIComponent(that.url);
                    that.$redirectAuth(redirectUrl);
                }
                this.getCouponData();

            }).catch(res => {
                let redirectUrl = '/auth/officialAccount?cid='+that.cid+'&check_code=' + that.check_code + '&module=officialAccount&target=' + encodeURIComponent(that.url);
                that.$redirectAuth(redirectUrl);
            })
        },
        getCouponData () {
            if (this.couponId) {
                couponShowApi({id: this.couponId}).then(res => {
                    console.log(res)
                    this.res = res.data
                    setTimeout(function () {
                        document.setTitle(res.data.coupon_name)
                    },1)

                    //设置分享
                    getSdkConfig({
                        url: window.location.href,
                        apiList: ['openLocation', 'updateAppMessageShareData', 'updateTimelineShareData','onMenuShareAppMessage', 'onMenuShareTimeline'],
                    }).then(sdk => {
                        this.$wx.init(sdk.data, () => {
                            this.$wx.share({
                                title: this.res.coupon_name,
                                desc: this.res.share_desc,
                                imgUrl: this.res.cover_img,
                                link: window.location.href
                            })
                        })
                    })
                })
            }
        },
        couponReceive () {
            var strToTime = function (dateStr) {
                var newstr = dateStr.replace(/-/g, '/');
                var date = new Date(newstr);
                var time_str = date.getTime().toString();
                return time_str;
            }
            
            if (this.res.status == 1) {
                if(Date.parse(new Date()) > strToTime(this.res.start_time)){
                    if(this.res.need_info){
                        this.showFrom = true
                    }else{
                        if(this.res.need_buy){
                            this.payHanld()
                        }else{
                            let params = {
                                id: this.res.id
                            }
                            if (this.res.limit_contact == 1) {
                                params.contact_id = this.contact_id
                            }
                            this.sendCouponReceiveApi(params)
                        }
                    }
                }else{
                    Toast('活动未开始')
                }
            } else {
                Toast('卡券已失效')
            }
        },
        sendCouponReceiveApi (params = {}) {
            couponReceiveApi(params).then(res => {
                if(res.code == 200){
                    Toast.success('奖励领取成功')
                    setTimeout(() => {
                        this.$router.push({path: '/coupon', query: {cid: this.cid}})
                    }, 2000);
                }else{
                    Toast(res.msg);
                }
            })
        },
        checkRule () {
            Dialog.alert({
                message: '弹窗内容',
                theme: 'round-button',
                confirmButtonColor: this.confirmButtonColor,
                allowHtml: true,
                message: this.res.desc || '无内容',
                messageAlign: 'left',
                className: 'couponDesc'
            }).then(() => {
            // on close
            });
        }
    }
}
</script>
<style lang="less">
.load {
    height: 100vh;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}
.coupon-detail {
    min-height: 100vh;
    padding-bottom:  55px;
    padding-bottom: calc(constant(safe-area-inset-bottom) + 65px); /* 兼容 iOS < 11.2 */
    padding-bottom: calc(env(safe-area-inset-bottom) + 65px); /* 兼容 iOS >= 11.2 */
    background-color: #f5f5f5;

    // .bg {
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     height: 100vh;
    //     width: 100%;
    //     background-size: cover;
    // }

    .bg_img {
        width: 100%;
        vertical-align: middle;
    }

    .intro {
        border-radius: 6px;
        border: 1px solid #eee;
        background: #fefefe;
        margin: 15px;
        padding: 12px;
        font-size: 12px;

        &-title {
        font-size: 14px;
        margin-bottom: 5px;
        }

        &-price {
        font-size: 14px;
        s {
            font-size: 12px;
            margin-right: 4px;
        }
        }
    }

    .rule {
        background: #fefefe;

        &-title {
        font-size: 14px;
        margin-bottom: 8px;
        }

        &-content {
        color: #666;
        }
        
        border-radius: 6px;
        border: 1px solid #eee;
        margin: 15px;
        padding: 12px;
        font-size: 12px;
    }

    // .rule-btn {
    //     position: fixed;
    //     top: 26vh;
    //     right: 0;
    //     z-index: 2;
    //     padding:10px 4px 6px 2px;
    //     background: #fff;
    //     border-radius: 4px 0 0 4px;
    //     box-shadow: -2px 4px 6px rgba(0, 0, 0, 0.2);
    //     font-size: 13px;
    //     writing-mode: vertical-lr;
    //     letter-spacing: 4px;
    // }

    &-box {
        position: fixed;
        display: flex;
        bottom: 0;
        left: 0;
        right: 0;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 10px;
        padding-bottom: 10px;
        background: #fff;
        box-shadow: 0 2px 8px rgba(0, 0, 0, .1);

        padding-bottom: calc(constant(safe-area-inset-bottom) - 10px); /* 兼容 iOS < 11.2 */
        padding-bottom: calc(env(safe-area-inset-bottom) - 10px); /* 兼容 iOS >= 11.2 */

        // display: flex;
        // height: 100vh;
        // padding-top: 22vh;

        >div {
        margin: 0 10px 10px;
        // margin-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
        // margin-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
        }

        .btn {
        background: #fff;
        flex: 1;
        background: #1989fa;
        border: 1px solid #1989fa;
        color: #fff;
        // font-weight: 600;
        }


        .btn:active {
        background: #0671de;
        }

        .my {
        flex: 1;
        background: #fff;
        border: 1px solid #ddd;
        text-align: center;
        a {
            color: #585858;
        }
        }

        .btn, .my {
        // line-height: 40px;
        border-radius: 40px;
        height: 42px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        // box-shadow: 2px 2px 6px rgba(0, 0, 0, .1);
        }

        .btn {
        span {
            line-height: 14px;
            font-size: 12px;
            margin-bottom: -3px;
        }
        }
    }

    // .content {
    //     padding: 20px;
    //     > div {
    //         background: #fff;
    //         border: 4px solid #f8386d;
    //         border-radius: 16px;
    //         padding: 30px;
    //         margin-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
    //         margin-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
    //     }
    // }

    
}
.couponDesc .van-dialog__message {
    img {
        max-width: 100%;
    }
}
.couponDesc  .van-dialog__footer {
    padding-top: 20px !important;
}
.van-cell-group {
    margin-bottom: 10px;
}
</style>
